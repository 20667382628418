<template>
  <MainLayout :title="title" v-on:logout="$emit('logout')">
    <p v-if="$mystore.state.questions.length === 0">
      <span style="white-space: pre-wrap"
        ><b>{{ $t('views.questions.noActiveQuestions') }}</b></span
      >
    </p>
    <v-list v-else>
      <v-list-item
        v-for="(question, key) in $mystore.state.questions"
        :key="key"
        :to="{ name: 'Question', params: { id: question.id } }"
      >
        <v-list-item-avatar>
          <v-icon class="primary white--text">mdi-help-circle</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            v-text="question.question"
            class="break-all"
          ></v-list-item-title>
        </v-list-item-content>

        <v-list-item-action v-if="noAuthorizers">
          <v-icon :color="question.voted ? 'success' : 'red'">{{
            question.voted ? 'mdi-check' : 'mdi-close'
          }}</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/layouts/Main.vue'
import handle_errors from '@/api/handle_errors.js'
import Question from '@/models/Question.js'
import catch_errors from '@/api/catch_errors.js'
import handle_token_refresh from '@/api/handle_token_refresh.js'

async function get_questions(token) {
  return await fetch(`${process.env.VUE_APP_API_URL}/voter/questions`, {
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

function map_questions(questions) {
  return questions.map(question => {
    return new Question(question)
  })
}

export default {
  name: 'Questions',

  components: {
    MainLayout,
  },

  data() {
    return {
      title: this.$t('views.questions.title'),
    }
  },

  watch: {
    '$i18n.locale': function () {
      this.title = this.$t('views.questions.title')
      document.title = this.title
    },
  },

  computed: {
    noAuthorizers() {
      if (this.$mystore.state.voter === null) {
        return true
      }

      return this.$mystore.state.voter.authorizers.length === 0
    },
  },

  beforeRouteEnter: function (to, from, next) {
    get_questions(localStorage.getItem('token'))
      .then(handle_token_refresh)
      .then(handle_errors)
      .then(data => {
        next(vm => vm.$mystore.setQuestions(map_questions(data.data.questions)))
      })
      .catch(response => {
        next(vm => {
          catch_errors(response, vm.$router, vm.$mystore)
        })
      })
  },

  created: function () {
    document.title = this.title
  },
}
</script>
